import topic from "@api/topic";
export const fcreAPI = {
    get_fcres: topic.fcreTopic + '/get_fcres',
    get_fcre_mtrbs_by_fcre_id: topic.fcreTopic + '/get_fcre_mtrbs_by_fcre_id',
    confirm_receipt: topic.fcreTopic + '/confirm_receipt',
    create_qrcode: topic.fcreTopic + '/create_qrcode',
    addFcre: topic.fcreTopic + '/add_fcre',
    getFcreByScan: topic.fcreTopic + '/get_fcre_by_scan', //工厂收货提醒
    countFcreByScan: topic.fcreTopic + '/count_fcre_by_scan', //工厂收货提醒数量
    editFcreSacn: topic.fcreTopic + '/edit_fcre_sacn', //工厂收货提醒数量确认
}