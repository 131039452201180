<template>
	<div>
		<el-dialog :visible.sync="dialogVisible" width="70%" :before-close="closeFcreDialog">
				<el-button type="primary" size="mini" @click="confirmReceipt" :disabled="fcreStatus === 2" class="vg_mb_5">确认收货</el-button>
				<el-button type="primary" size="mini" @click="showPopovers" :disabled="fcreStatus === 2" class="vg_mb_5">批量下载</el-button>
				<el-row>
					<el-col :md="24">
						<el-table ref="multiTable" :data="tableData" border v-loading="loadingFlag" @selection-change="handleSelectionChange">
							<el-table-column type="selection" width="48" align="center"/>
							<el-table-column label="材料采购号" prop="modr_no"/>
							<el-table-column label="成品采购号" prop="podr_no"/>
							<el-table-column label="材料名称" prop="mtrb_name"/>
							<el-table-column label="总箱数" prop="fcre_mtrb_box">
								<template v-slot="scope">
									<span v-if="scope.row.fcre_mtrb_box">
											{{ scope.row.fcre_mtrb_box}}
									</span>
									<span v-else>拼箱</span>
								</template>
							</el-table-column>
							<el-table-column label="应收数量" prop="fcre_mtrb_num"/>
							<el-table-column label="已收数量" prop="fcre_mtrb_rnum"/>
							<el-table-column label="本次收货数量" prop="fcre_mtrb_bnum" width="150px">
								<template v-slot="scope">
									<el-input-number :max="tableData[scope.$index].fcre_mtrb_maxnum" :disabled="fcreStatus === 2" size="small" placeholder="请输入数量" controls-position="right" :min="0"
									                 v-model="tableData[scope.$index].fcre_mtrb_bnum"></el-input-number>
								</template>
							</el-table-column>
							<el-table-column label="物流单号及司机联系方式" prop="fcre_mtrb_bnum" width="150px">
								<template v-slot="scope">
									<el-popover
										placement="left"
										width="600"
										trigger="click">
										<div style="height:600px;
											overflow-y: scroll !important;
											width: 100%;
											overflow-x: hidden;">
											<el-timeline :reverse="false">
												<el-timeline-item
														v-for="(activity, index) in expressInfo"
														:key="index"
														:timestamp="activity.time">
													{{activity.context}}
												</el-timeline-item>
											</el-timeline>
										</div>
										<el-link slot="reference" type="primary" size="mini" style="width: 100%"  @click="showDialog('JD0090330304786')">{{scope.row.logistics_no}}</el-link>
									</el-popover>
									<div>{{scope.row.driv_name}}{{scope.row.driv_phone}}</div>
								</template>
							</el-table-column>
							<el-table-column label="二维码" prop="qrcd_url">
								<template v-slot="scope">
									<div v-if="scope.row.qrcd_url" style="display: flex">
										<el-popover
												:ref="`popoverNode${scope.row.fcre_mtrb_id}`"
												placement="left-start"
												title="打印预览"
												trigger="hover">
											<div :id="`printNode${scope.row.fcre_mtrb_id}`" style="text-align: center" >
												<div style="height: 5cm;width: 4cm;flex-direction: column;">
													<el-image :src="helper.picUrl(scope.row.qrcd_url, 'l', '', 0)"/>
													<span style="font-size: 22px;font-weight: 800">{{`FM${scope.row.fcre_mtrb_id}`}}</span>
												</div>
											</div>
											<div slot="reference" style="display: flex;justify-content: center;align-items: center">
												<el-image style="width: 50px; height: 50px" :src="scope.row.qrcd_url ? helper.picUrl(scope.row.qrcd_url, 'l', '', 0): ''"/>
												<el-button type="text" size="mini" @click="printQRCode(scope.row.fcre_mtrb_id)">打印二维码</el-button>
											</div>
										</el-popover>
									</div>
									<span v-else>暂无</span>
								</template>
							</el-table-column>
							<el-table-column label="操作" prop="mtrl_no" align="center">
								<template v-slot="scope">
									<el-button :disabled="scope.row.qrcd_url !== ''" type="text" size="mini" @click="generateQRCode(scope.row)">生成二维码</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-col>
					<el-col :md="24">
						<pub-pagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"/>
					</el-col>
				</el-row>
		</el-dialog>
	</div>
</template>

<script>
import PubPagination from "@/components/common/pubPagination";
import {get, post} from "@api/request";
import {fcreAPI} from "@api/modules/fcre";
import helper from "@assets/js/helper";
import printJS from "print-js";
import domtoimage from 'dom-to-image';
import JSZip from "jszip";
import {saveAs} from 'file-saver';
export default {
	name: "FcreDialog",
	components: { PubPagination},
	props: {
		dialogVisible: {
			type: Boolean,
			default: false,
		},
		fcreId: {
			type: Number,
			default: 0,
		},
		fcreStatus: {
			type: Number,
			default: 0,
		},
		page_no: {
			type: Number,
			default: 0,
		}
	},
	data() {
		return {
			searchForm: {page_no: 1},
			totalPage: 0,
			tableData: [],
			btn: {},
			loadingFlag: true,
			currentPage: 1,
			multiSelection: [],
			dialogVisible2:false,
			expressInfo:[],
		}
	},
	watch: {
		fcreId(newValue, oldValue) {
			if (newValue !== 0) {
				this.getDataLatest();
			}
		}
	},
	mounted() {
	},
	methods: {
		getDataLatest() {
			this.loadingFlag = true;
			get(fcreAPI.get_fcre_mtrbs_by_fcre_id, {fcre_id: this.fcreId}).then(({data}) => {
				if (data.code === 0) {
					this.tableData = data.data.list;
					this.tableData.forEach((item) => {
						// if (item.fcre_mtrb_rnum === 0) {
						// 	item.fcre_mtrb_rnum = item.fcre_mtrb_num
						// }
						item.fcre_mtrb_bnum = Number(item.fcre_mtrb_num) - Number(item.fcre_mtrb_rnum)
						item.fcre_mtrb_maxnum = Number(item.fcre_mtrb_num) - Number(item.fcre_mtrb_rnum)
					})
					this.totalPage = data.data.total
					this.btn = data.data.btn;
					setTimeout(() => {
						this.loadingFlag = false
					}, 500);
				}
			});
		},
		showDialog(val) {
			if(!val) return this.$message.warning("请填写快递单号!");
			this.dialogVisible2 = true;
			post("/api/kuaidi100/realTimeQueryTrack", {num: val}).then(({data}) => {
				if (data.status === '200') {
					this.expressInfo = data.data
				} else {
					this.$message.warning("请检查快递单号!");
				}
			});
		},
		// 分页查询
		changePageSearch(val) {
			this.searchForm.page_no = val;
			this.initData();
		},
		closeFcreDialog() {
			this.$emit("closeFcreDialog",this.page_no)
		},
		confirmReceipt() {
			if (this.multiSelection.length === 0) {
				this.$message.warning("请至少选择一项")
				return
			}
			let flag = false;
			this.multiSelection.forEach(item => {
				if(item.qrcd_url === ''){
					flag = true
				}
			});
			if(flag) return this.$message.warning('请先生成二维码')
			post(fcreAPI.confirm_receipt, this.multiSelection).then(({data}) => {
				if (data.code === 0) {
					this.getDataLatest();
					this.$message.success("收货成功!")
					this.closeFcreDialog();
				}
			});
		},
		handleSelectionChange(val) {
			this.multiSelection = val;
		},
		generateQRCode(row) {
			post(fcreAPI.create_qrcode,{fcre_mtrb_id: row.fcre_mtrb_id}).then(({data}) => {
				if(data.code === 0) {
					row.qrcd_url = data.data.qrcd_url
				}
			})
		},
		printQRCode(id) {
			setTimeout(() => {
				printJS({
					printable: `printNode${id}`,
					type: 'html',
					header: '',
					targetStyles: ['*'],
					style: '@page {margin:0 10px 0 0;size: 4cm 5cm;text-align:center;};',
				});
			},500)
		},
		showPopovers() {
			this.tableData.forEach((item) => {
				if (item.qrcd_url) {
					this.$refs[`popoverNode${item.fcre_mtrb_id}`].showPopper = true
				}
			});
			this.batchUownload();
		},
		// 批量下载
		batchUownload() {
			let imgArr = [];
			this.tableData.forEach((item) => {
				if (item.qrcd_url) {
					let node = document.getElementById(`printNode${item.fcre_mtrb_id}`);
					domtoimage.toPng(node).then((dataUrl) => {
						imgArr.push(dataUrl);
					}).catch(function (error) {
						console.error('oops, something went wrong!', error);
					});
				}
			});
			let filter = this.tableData.filter(x => {if(x.qrcd_url) return x});
			setTimeout(() => {
				const zip = new JSZip();   // 实例化zip
				const img = zip.folder("qrCode");   // zip包内的文件夹名字
				imgArr.forEach((item, index) => {  // listOfData是含有图片的数据数组
					const basePic = item.replace(/^data:image\/(png|jpg);base64,/, "");  // 生成base64图片数据
					img.file(`${filter[index]?.modr_no}_${filter[index]?.mtrb_name}.png`, basePic, {base64: true});  // 将图片文件加入到zip包内
					this.$refs[`popoverNode${filter[index].fcre_mtrb_id}`].showPopper = false
				})
				zip.generateAsync({type: "blob"})   // zip下载
						.then(function (content) {
							saveAs(content, "二维码.zip");  // zip下载后的名字
						});
			}, 2000);
		},
	},
}
</script>

<style scoped lang="scss">
::v-deep .vd_hei{
	min-height: 600px;
	height: 600px !important;
	overflow-y: scroll !important;
	width: 100%;
	overflow-x: hidden;
	background-color: aqua;
}
::v-deep #el-popover-428{
	height: 600px !important;
	overflow-y: scroll !important;
	width: 100%;
}
</style>