<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" size="mini" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="getTableData()">
          <el-row class="vg_mb_5">
            <el-col :md="8">
              <el-form-item label="材料采购号:" prop="modr_no">
                <el-input size="small" v-model.trim="searchForm.modr_no" clearable placeholder="请填写材料采购号" />
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="采购经办人:" prop="puca_stff_name">
                <el-select filterable v-model="searchForm.puca_stff_name" placeholder="请选择采购经办人" size="small" clearable>
                  <el-option v-for="item in stffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="收货状态:" prop="dent_status">
                <el-select filterable v-model="searchForm.fcre_status" placeholder="请选择收货状态" size="small" clearable>
                  <el-option v-for="item in statusList" :key="item.id" :label="item.label" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="8">
              <el-form-item label="成品采购号:" prop="podr_no">
                <el-input size="small" v-model.trim="searchForm.podr_no" clearable placeholder="请填写成品采购号" />
              </el-form-item>
            </el-col>
            <el-col :md="16">
              <el-form-item label="收货时间:">
                <el-date-picker
                  size="small"
                  v-model="timeArray"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
              </el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getTableDataNow()" class="vg_ml_16">查询</el-button>
              <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="buttonRefresh()">刷新</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16"></div>
      <el-row>
        <el-col :md="24">
          <el-table class="vg_cursor" :data="tableData" border ref="multiTable" v-loading="loadingFlag" stripe @sort-change="sortChange">
            <el-table-column type="index" label="序号" align="center" />
            <el-table-column label="材料采购号" prop="modr_no" />
            <el-table-column label="成品采购号" prop="podr_no" />
            <el-table-column label="订单总箱数" prop="fcre_tbox" />
            <el-table-column label="客户简称" prop="cust_abbr">
              <template v-slot="scope">
                <span v-if="scope.row.cust_abbr">{{ scope.row.cust_abbr }}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="发货日期" prop="dent_date" sortable="custom">
              <template v-slot="scope">
                <span v-if="scope.row.dent_date">
                  {{ scope.row.dent_date | formatDate }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="收货日期" prop="fcre_date" sortable="custom">
              <template v-slot="scope">
                <span v-if="scope.row.fcre_date">
                  {{ scope.row.fcre_date | formatDate }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="采购经办人" prop="puca_stff_name" />
            <el-table-column label="收货状态" show-overflow-tooltip align="center">
              <template v-slot="scope">
                <el-tag v-if="scope.row.fcre_status === 1" type="warning" size="mini">部分收货</el-tag>
                <el-tag v-if="scope.row.fcre_status === 0" type="info" size="mini">未收货</el-tag>
                <el-tag v-if="scope.row.fcre_status === 2" type="success" size="mini">已收货</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="tran_usdrate" align="center">
              <template v-slot="scope">
                <el-button
                  size="mini"
                  type="warning"
                  plain
                  @click="receiveGoods(scope.row)"
                  v-if="scope.row.fcre_status !== 2 && userInfo.acct_id !== 1"
                  :disabled="scope.row.fcre_status === 2"
                  >收货</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  @click="receiveGoods(scope.row)"
                  v-if="scope.row.fcre_status === 2 && userInfo.acct_id !== 1"
                  >查看详情</el-button
                >
                <span v-if="userInfo.acct_id === 1" class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination" />
        </el-col>
      </el-row>
    </el-card>
    <FcreDialog
      :fcreId="fcreId"
      :page_no="searchForm.page_no"
      :fcreStatus="fcreStatus"
      :dialogVisible="fcreDialogVisible"
      @closeFcreDialog="closeFcreDialog"
    />
  </div>
</template>

<script>
import { get } from '@api/request';
import pubPagination from '@/components/common/pubPagination';
import helper from '@assets/js/helper.js';
import { fcreAPI } from '@api/modules/fcre';
import FcreDialog from '@/views/PurcManagement/FcreManage/Dialog/FcreDialog';
import { stffAPI } from '@api/modules/staff';

export default {
  name: 'FcreList',
  components: {
    FcreDialog,
    pubPagination
  },
  data() {
    return {
      timeArray: [],
      searchForm: {
        page_no: 1
      },
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      btn: {},
      loadingFlag: true,
      statusList: [
        { id: 0, label: '未收货' },
        { id: 1, label: '部分收货' },
        { id: 2, label: '已收货' }
      ],
      fcreDialogVisible: false,
      fcreId: 0,
      stffList: [],
      fcreStatus: 0,
      userInfo: {}
    };
  },
  created() {
    this.userInfo = this.$cookies.get('userInfo');
    if (this.$route.query.modr_no) {
      this.searchForm.modr_no = this.$route.query.modr_no;
    }
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (to.path === '/fcre_list') {
        this.initData();
      }
    },
    'searchForm.puca_stff_name'(newValue, oldValue) {
      if (newValue) {
        this.searchForm.puca_stff_id = this.stffList.find(({ stff_name }) => stff_name === newValue).stff_id;
      }
    }
  },
  filters: {
    // 时间转换
    formatDate(row) {
      return helper.toStringDay(row);
    }
  },
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getTableData();
      this.getStffUser();
    },
    // 获取表格数据
    getTableData() {
      const { startTime, endTime } = this.helper.getTime(this.timeArray);
      this.searchForm.start_time = startTime;
      this.searchForm.end_time = endTime;
      get(fcreAPI.get_fcres, this.searchForm).then(({ data }) => {
        if (data.code === 0) {
          this.tableData = data.data.list;
          this.totalPage = data.data.total;
          this.btn = data.data.btn;
          setTimeout(() => {
            this.loadingFlag = false;
          }, 500);
        }
      });
    },
    // 刷新
    buttonRefresh() {
      this.loadingFlag = true;
      this.currentPage = 1;
      this.searchForm = {};
      this.searchForm.page_no = 1;
      this.timeArray = [];
      this.$refs.pubPagination.currentPage = 1;
      this.$refs['searchForm'].resetFields();
      this.initData();
    },
    // 查询方法
    getTableDataNow() {
      this.loadingFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.searchForm.page_no = 1;
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.initData();
    },
    receiveGoods(row) {
      this.showFcreDialog();
      this.fcreId = row.fcre_id;
      this.fcreStatus = row.fcre_status;
    },
    showFcreDialog() {
      this.fcreDialogVisible = true;
    },
    closeFcreDialog(pageNo) {
      this.fcreDialogVisible = false;
      this.fcreId = 0;
      setTimeout(() => {
        this.searchForm.page_no = pageNo;
        this.getTableData();
      }, 500);
    },
    getStffUser() {
      get(stffAPI.getAllStffsV1, {}).then(res => {
        if (res.data.code === 0) {
          this.stffList = res.data.data;
        }
      });
    },
    /**
     * 排序参数
     * @param order 排序正倒序
     * @param prop 指定排序字段
     */
    sortChange({ order, prop }) {
      if (!order) {
        this.searchForm.column = '';
        this.searchForm.order = '';
        return;
      }
      this.searchForm.column = prop;
      this.searchForm.order = order.substring(0, order.indexOf('ending'));
      this.getTableDataNow();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_button_group {
  width: 100vw;
  position: relative;
}
</style>
